 


<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="email">Email</label>
          <vs-input
            v-validate="'required|email|min:3'"
            data-vv-validate-on="blur"
            name="email"
            icon-no-border
            icon="icon icon-mail"
            icon-pack="feather"
            v-model="userDetail.email"
            class="w-full"
            :disabled="userDetail._id"
          />
          <span class="text-danger text-sm">{{ errors.first('email') }}</span>
          <!-- <span class="text-danger text-sm">{{ msgInUse }}</span> -->
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="contactNumber">Phone Number</label>
          <vs-input
            v-validate="'required|max:10'"
            data-vv-validate-on="blur"
            maxlength="10"
            name="contactNumber"
            icon-no-border
            icon="icon icon-phone"
            icon-pack="feather"
            v-model="userDetail.contactNumber"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('contactNumber') }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="firstName">First Name</label>
          <vs-input
            data-vv-validate-on="blur"
            v-validate="'required|max:20'"
            name="firstName"
            maxlength="20"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            v-model="userDetail.firstName"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('firstName') }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="lastName">Last Name</label>
          <vs-input
            data-vv-validate-on="blur"
            v-validate="'required|max:20'"
            name="lastName"
            maxlength="20"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            v-model="userDetail.lastName"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('lastName') }}</span>
        </div>
      </vs-col>
      <vs-divider position="left" color="danger">Contact Information</vs-divider>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="email2">Contact Email</label>
          <vs-input
            v-validate="'email|min:3'"
            data-vv-validate-on="blur"
            name="email2"
            icon-no-border
            icon="icon icon-mail"
            icon-pack="feather"
            v-model="userDetail.contactInformation.email"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('email2') }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="status">Status</label>
          <vs-select v-model="select3" @change="userState(select3)">
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in options3"
            />
          </vs-select>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="contactName">Contact Name</label>
          <vs-input
            data-vv-validate-on="blur"
            v-validate="'max:20'"
            name="contactName"
            maxlength="20"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            v-model="userDetail.contactInformation.contactName"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('contactName') }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="contactNumberOpt">Contact Number</label>
          <vs-input
            v-validate="'max:10'"
            data-vv-validate-on="blur"
            maxlength="10"
            name="contactNumberOpt"
            icon-no-border
            icon="icon icon-phone"
            icon-pack="feather"
            v-model="userDetail.contactInformation.contactNumber"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('contactNumberOpt') }}</span>
        </div>
      </vs-col>
      <!-- users displayaddress is mailing address in contactinformation -->
      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="displayAddress">Display Address</label>
          <vs-input
            data-vv-validate-on="blur"
            v-validate="'max:30'"
            name="displayAddress"
            maxlength="30"
            icon-no-border
            icon="icon icon-map-pin"
            icon-pack="feather"
            v-model="userDetail.contactInformation.displayAddress"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('displayAddress') }}</span>
        </div>
      </vs-col>-->
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="displayAddress">Display Address</label>
          <!-- its showing as display address but we need mailing address in contact information -->
          <!-- just diffrence of naming in users and contact information for address -->
          <vs-input
            data-vv-validate-on="blur"
            v-validate="'max:50'"
            name="displayAddress"
            maxlength="50"
            icon-no-border
            icon="icon icon-inbox"
            icon-pack="feather"
            v-model="userDetail.contactInformation.mailingAddress"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('displayAddress') }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="allowSms">Allow Sms</label>
          <vs-select v-model="select1" @change="sms(select1)">
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in options1"
            />
          </vs-select>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="allowCall">Allow Call</label>
          <vs-select v-model="select2" @change="call(select2)">
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in options2"
            />
          </vs-select>
        </div>
      </vs-col>
      <vs-row vs-justify="flex-end">
        <vs-button color="success" v-if="userDetail._id" @click="updateUserDetail">Update</vs-button>
        <vs-button color="success" v-if="!userDetail._id" @click="addNewUserDetail">Save</vs-button>
        <vs-button
          color="danger"
          style="margin-left:5px;"
          @click="$router.replace('/admin/user-management')"
        >Cancel</vs-button>
      </vs-row>
    </vs-row>
  </vx-card>
  <!-- commented old one -->

  <!-- <vx-card slot="no-body" class="tabs-container px-6 pt-6">
    <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
      <vs-tab label="Basic" icon-pack="feather" icon="icon-flag">
        <div class="tab-text">  
        <basic-edit :data="userDetail"  ></basic-edit>
        </div> 
      </vs-tab>
      <vs-tab label="Contact Information" icon-pack="feather" icon="icon-user">
        <div class="tab-text">
          <contact-edit :data="userDetail"></contact-edit>
        </div>
      </vs-tab>
    </vs-tabs>
      <vs-row vs-justify="flex-end">
      <vs-button color="success" v-if="userDetail._id" @click="updateUserDetail">Update</vs-button>
      <vs-button color="success" v-if="!userDetail._id" @click="addNewUserDetail">Save</vs-button>
      <vs-button
        color="danger"
        style="margin-left:5px;"
        @click="$router.replace('/admin/user-management')"
      >Cancel</vs-button>
    </vs-row> 
  </vx-card>-->
</template>

  <script>
import { mapActions } from "vuex";
import moment from "moment";
import { Validator } from "vee-validate";
// import BasicEdit from "./basicEdit.vue";
// import ContactEdit from "./contactEdit.vue";

export default {
  name: "UserDetails",

  // components: {
  //   BasicEdit,
  //   ContactEdit,
  // },
  data: () => ({
    activeTab: 0,
    select1: false,
    select2: false,
    select3: "Active",
    // msgInUse: "",
    options1: [
      { text: "No", value: false },
      { text: "Yes", value: true },
    ],
    options2: [
      { text: "No", value: false },
      { text: "Yes", value: true },
    ],
    options3: [
      { text: "Active", value: "Active" },
      { text: "Disabled", value: "Disabled" },
    ],
    userDetail: {
      contactInformation: {
        allowCall: true,
        allowSms: false,
      },
    },
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.userDetail.email != "" &&
        this.userDetail.firstName != "" &&
        this.userDetail.lastName != "" &&
        this.userDetail.contactNumber != ""
      );
    },
  },
  methods: {
    ...mapActions("user", ["fetchUser", "updateUserDetails", "addNewUser"]),
    sms(val) {
      this.userDetail.contactInformation.allowSms = val;
    },
    call(val) {
      this.userDetail.contactInformation.allowCall = val;
    },
    userState(val) {
      this.userDetail.status = val;
    },
    getUserDetail(id) {
      let self = this;
      this.fetchUser(id).then((res) => {
        this.userDetail = res.data.data;
        this.select1 = this.userDetail.contactInformation.allowSms;
        this.select2 = this.userDetail.contactInformation.allowCall;
      });
    },
    updateUserDetail() {
      this.updateUserDetails(this.userDetail).then((res) => {
        this.$vs.notify({
          title: "User Updated",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.$router.push(`/admin/user-management/edit/${this.userDetail._id}`);
      });
    },

    addNewUserDetail() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.addNewUser(this.userDetail).then((res) => {
            if (res.data.data) {
              this.$vs.notify({
                title: "User Added",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.$router.push(
                `/admin/user-management/edit/${res.data.data._id}`
              );
              this.getUserDetail(res.data.data._id);
            } else {
              //for email already i use
              this.$vs.notify({
                title: "Error",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
              // this.msgInUse = res.data.message;
            }
          });
        }
      });
    },
  },
  created() {
    let userId = this.$route.params.id;
    if (userId) {
      this.getUserDetail(userId);
    } else {
      this.userDetail = {
        contactInformation: {
          allowCall: "false",
          allowSms: "false",
        },
      };
    }
  },
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
</style>  